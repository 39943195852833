import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../../../components/Layout'
import HeaderSEO from '../../../components/partials/HeaderSEO'
import Spacing from '../../../components/partials/Spacing'
import { StaticImage } from 'gatsby-plugin-image'
import EventRegistrationForm from '../../../components/EventRegistrationForm'

const EventRegistration = () => {
    const data = useStaticQuery(graphql`
        query eventRegistrationQuery {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "EventRegistration" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout>
            <HeaderSEO pageSEO={pageSEO} />
            <Spacing />

            <section className='EventRegistration--hero'>
                <div className='container-1 EventRegistration--hero__content'>
                    <h1 className='cera-pro'>Meetup | Kafka Horror Stories</h1>
                    <h2 className='cera-pro'>Featuring Adidas & AWS</h2>
                    <h3 className='roboto EventRegistration--hero__h3-primary'>
                        Tuesday 1st October | 6:30-9pm
                    </h3>
                    <h3 className='roboto EventRegistration--hero__h3-secondary'>
                        Celonis Office, Pl. de Manuel Gómez-Moreno, 2, <br /> Tetuán, 28020 Madrid,
                        Spain
                    </h3>
                </div>
            </section>
            <section className='EventRegistration--under-hero'>
                <div className='container-1'>
                    <p>
                        Welcome to this seasonal meetup, exploring the potential and spooky
                        peculiarities of Apache Kafka, and how to handle them. We provide snacks,
                        drinks, and stories from the Kafka community.
                    </p>
                </div>
            </section>
            <div className='EventRegistration--form'>
                <EventRegistrationForm />
            </div>
            <Spacing />
            <section className='roboto EventRegistration--feature'>
                <div className='container-1'>
                    <h4 className='EventRegistration--feature__company cera-pro'>ADIDAS</h4>
                    <h1 className='EventRegistration--feature__title cera-pro'>
                        How to protect yourself from <br /> a Kafka Connector storm?
                    </h1>
                    <p>
                        It’s Friday, it’s 3pm, and you are hoping for a quiet start to the weekend.
                        You receive a call: Looks like some clients can’t connect to Kafka, but the
                        infrastructure seems healthy and operational. After some time, the list of
                        clients affected is growing and growing, but everything seems to be stable
                        at a glance. What’s going on?
                    </p>
                    <p>
                        In this 30 minute talk, Rafael Garcia, Platform Engineer at Adidas covers
                        how you can approach failures like this, and how to prevent them from
                        happening in the first place:
                    </p>
                    <ul>
                        <li>Root cause analysis</li>
                        <li>Blast radius</li>
                        <li>Key metrics to monitor</li>
                        <li>Counter measurements and remediations.</li>
                    </ul>
                    <p>
                        <span className='EventRegistration--feature__speaker'>Speaker:</span> Jose
                        Rafael Garcia, Platform Engineer - Adidas
                    </p>
                </div>
            </section>
            <Spacing />
            <section className='container-1 roboto EventRegistration--feature'>
                <h4 className='EventRegistration--feature__company cera-pro'>AWS</h4>
                <h1 className='EventRegistration--feature__title cera-pro'>
                    Taming silos and stale data with <br /> Streaming Data Lakes
                </h1>
                <p>
                    Let’s face some of the scariest challenges lurking in your data infrastructure.
                    Data silos and stale data haunt your analytics and slow down your business. But
                    streaming data lakes are here to save the day. We uncover how Apache Kafka,
                    Apache Flink, and Apache Iceberg can help you ensure your data stays fresh,
                    accessible, and ready to deliver real-time insights.
                </p>
                <p>
                    <span className='EventRegistration--feature__speaker'>Speaker:</span> Francisco
                    Morillo is a Streaming Solutions Architect at AWS with over five years of
                    experience in real-time analytics. He specializes in Amazon MSK and Amazon
                    Flink, helping AWS customers build scalable streaming data solutions and data
                    lakes for real-time insights.
                </p>
            </section>
            <Spacing />
            <section className={' mt-5 mt-sm-0'}>
                <div className='sectionWithGuyBlackStrip' style={{ paddingBottom: '50px' }}>
                    <div className='d-flex flex-wrap align-items-bottom'>
                        <div
                            className='container-1 col-12 text-left mt-3 mb-4'
                            style={{ maxHeight: '170px', paddingLeft: 0 }}>
                            <h2 className='f-40 lh-52 pb-0 text-white'>
                                Build better, sleep easy <br className={'onlyDesktop'} />
                                with the developer experience <br className={'onlyDesktop'} /> for
                                any Kafka
                            </h2>
                            <div className='roll-button navbar-dl-button heroLottieButton'>
                                <a
                                    id='navbar-download'
                                    style={{ fontSize: '1rem' }}
                                    className='text-white mr-0 bg-red'
                                    href='/start/'>
                                    <span data-hover='Start free'>Start free</span>
                                </a>
                            </div>
                        </div>
                        <StaticImage
                            className='pt-2 w-35 sectionWithGuyImage'
                            style={{ left: '50%' }}
                            src='../resources/images/new_homepage/developer-experience.webp'
                            placeholder='tracedSVG'
                            alt='Security'
                        />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default EventRegistration
